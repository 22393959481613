

































import { Vue, Component, Prop } from 'vue-property-decorator';
import { SavingsServiceType } from '@/vue-app/types';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';

@Component({
  name: 'ContractSavingsStart',
  components: {
    ContractSavingsPrepareDocuments: () => import('./before-start/ContractSavingsPrepareDocuments.vue'),
  },
})
export default class ContractSavingsStart extends Vue {
  @Prop(String)
  readonly service!: SavingsServiceType;

  i18n_namespace = 'components.contract-savings.before-start';

  small_screen: null | boolean = null;

  created() {
    this.small_screen = getScreenSizeVariant() === 'small';
    this.$emit('loadingInfo', false);
  }
}

